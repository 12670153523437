import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { AiFillFilter } from 'react-icons/ai';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getTips, onSearchData, onSaveTip, getTipByDate, onFinishTip } from '../../Redux/tipDuck';
import { toogleLoader } from '../../Redux/alertDuck';
import ItemTip from './item.tip';
import TemplateMenu from '../../template/menu.template'
import { formatNumber } from '../../utils';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const API_URL = process.env.REACT_APP_API_URL


const TipsPage = ({ data, onSearchData, fetching_search, onSaveTip, getTipByDate,
    fetching_current, row, toogleLoader, fetching_create, onFinishTip, fetching_finish,
    pdfs
}) => {

    const [listWorker, setListWorker] = useState([]);
    const [allowed, setAllowed] = useState(0);
    const [dataTip, setDataTip] = useState({ id: 0 });
    const [tip, setTip] = useState(0);
    const [hasTip, setHasTip] = useState(false);

    useEffect(() => {
        onSearchData()
        getTipByDate()
    }, [])

    useEffect(() => {
        if (fetching_search === 2) {
            let { workers, allowed, tips } = data

            setAllowed(allowed)

            setTip(tips)
            let list = workers.map(item => ({
                ...item, worker_days: 30, assign_point: 8, points_worker_days: 8 / 30 * 30,
                percent: (8 / 30 * 30) * 100, discount: 0, total: 0
            }))

            setListWorker(list)
        }
    }, [fetching_search])

    useEffect(() => {
        if (fetching_create === 2) {
            toogleLoader(false)
        }
    }, [fetching_create])

    useEffect(() => {
        if (fetching_current === 2 && fetching_search === 2) {
            let { workers, allowed } = data
            if (row !== null) {
                let { detail, } = row
                setHasTip(true)
                setDataTip(row)

                let total = detail.reduce((a, b) => a + parseInt(b.points), 0)

                let _filter = workers.map(d => {
                    detail.forEach(element => {
                        if (element.worker_id === d.id) {
                            d.assign_point = element.points;
                            d.worker_days = element.days;
                            d.points_worker_days = (element.points / 30 * element.days)
                            d.percent = (d.points_worker_days * 100) / total
                            d.total = ((d.percent * allowed) / 100);
                        }
                    });
                    return d
                })

                console.log('_filter', _filter);

                setListWorker(_filter)
            }

        }
    }, [fetching_current, fetching_search])

    useEffect(() => {
        if (fetching_finish === 2) {
            toogleLoader(false)
            console.log(window.open(`${API_URL}/${pdfs.pdf}`, '_blank'));
        }
    }, [fetching_finish])

    const updateRow = (row) => {
        let _filter = listWorker.map(d => {
            if (d.id === row.id) {
                d.points_worker_days = (d.assign_point / 30 * d.worker_days)
                d.percent = d.points_worker_days * 100
            }
            return d
        })

        let total = listWorker.reduce((a, b) => a + b.points_worker_days, 0)

        _filter = listWorker.map(d => {
            d.points_worker_days = (d.assign_point / 30 * d.worker_days)
            d.percent = (d.points_worker_days * 100) / total
            d.total = ((d.percent * allowed) / 100);
            return d
        });

        setListWorker(_filter)
    }

    const updateRowMonth = (row) => {

        let _filter = listWorker.map(d => {
            if (d.id === row.id) {
                d.worker_days = row.worker_days
                d.points_worker_days = (d.assign_point / 30 * d.worker_days)
            }
            return d
        })

        let total = listWorker.reduce((a, b) => a + b.points_worker_days, 0)

        _filter = listWorker.map(d => {
            d.points_worker_days = (d.assign_point / 30 * d.worker_days)
            d.percent = (d.points_worker_days * 100) / total
            d.total = ((d.percent * allowed) / 100);
            return d
        });
        setListWorker(_filter)
    }

    const onSaveTipForm = () => {
        toogleLoader(true)
        onSaveTip(tip, allowed, listWorker)
    }

    const onFinish = () => {
        if (window.confirm('¿Deseas finalizar el pago de Propina?')) {
            toogleLoader(true)
            onFinishTip(dataTip.id)
        }
    }


    return (<TemplateMenu>
        <Container fluid>
            <Row className="my-4">
                <Col lg={3} md={4}>
                    <h4 className="font-title pt-3">LIQUIDACION PROPINAS</h4>
                </Col>
                <Col lg={5}>
                    <Row>
                        <Col>Total Recaudado</Col>
                        <Col>{formatNumber(tip)}</Col>
                    </Row>
                    <Row>
                        <Col>Total Repartir</Col>
                        <Col>{formatNumber(allowed)}</Col>
                    </Row>
                    <Row>
                        <Col>Total Puntos</Col>
                        <Col>{formatNumber(listWorker.reduce((a, b) => a + parseFloat(b.assign_point), 0))}</Col>
                    </Row>
                    <Row>
                        <Col>Valor del Punto</Col>
                    </Row>
                </Col>
                <Col className='d-flex align-items-center'>
                    <button className='btn btn-sm btn-success ml-4' onClick={onFinish}>Finalizar </button>
                    {dataTip.status_id === 1 && <>
                        <button className='btn btn-sm btn-info' onClick={onSaveTipForm}>Guardar</button>
                        {hasTip &&
                            <button className='btn btn-sm btn-success ml-4' onClick={onFinish}>Finalizar </button>}
                    </>}

                </Col>

            </Row>
            <Row>
                <Col lg={12}>
                    <table className="table table-sm table-bordered">
                        <thead className="thead-dark">
                            <tr>
                                <th>Nombre</th>
                                <th>Cedula</th>
                                <th>Cargo</th>
                                <th>PUNTOS ASIGNADOS POR CARGO</th>
                                <th>PUNTOS X DIAS TRABAJADOS</th>
                                <th>DIAS TRABAJADOS</th>
                                <th>PORCENTAJE</th>
                                <th>VALOR A PAGAR</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(listWorker.length > 0) ? listWorker.map(row =>
                                <ItemTip item={row} key={row.id}
                                    updateRow={updateRow}
                                    updateRowMonth={updateRowMonth}
                                />) : <tr><td colSpan={14} className="text-center">No Tienes Registros</td></tr>}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colSpan={3}>TOTAL</th>
                                <th>{formatNumber(listWorker.reduce((a, b) => a + parseFloat(b.assign_point), 0))}</th>
                                <th>{formatNumber(listWorker.reduce((a, b) => a + parseFloat(b.points_worker_days), 0))}</th>
                                <th></th>
                                <th className='text-right'>{formatNumber(listWorker.reduce((a, b) => a + parseFloat(b.percent), 0))}</th>
                                <th className='text-right'>{formatNumber(listWorker.reduce((a, b) => a + parseFloat(b.total), 0))}</th>
                            </tr>
                        </tfoot>
                    </table>
                </Col>
            </Row >
        </Container>
    </TemplateMenu>
    )
}

const mapsState = (props) => {
    let { tip } = props

    return {
        fetching_search: tip.fetching_search,
        fetching_current: tip.fetching_current,
        fetching_create: tip.fetching_create,
        fetching_finish: tip.fetching_finish,
        pdfs: tip.pdfs,
        data: tip.data,
        row: tip.row,
    }
}

export default connect(mapsState, {
    getTips, onSearchData, onSaveTip, getTipByDate, toogleLoader,
    onFinishTip
})(TipsPage);