import { getHeaders } from '../utils';
const API_URL = process.env.REACT_APP_API_URL
let initialState = {
    fetching_list: 0,
    fetching_search: 0,
    fetching_create: 0,
    fetching_current: 0,
    fetching_by_id: 0,
    fetching_finish: 0,
    fetching_delete: 0,
    data: [],
    row: {},
    pdfs: []
}

let LIST_TIPS = "LIST_TIPS";
let LIST_TIPS_SUCCESS = "LIST_TIPS_SUCCESS";
let CURRENT_TIPS = "CURRENT_TIPS";
let CURRENT_TIPS_SUCCESS = "CURRENT_TIPS_SUCCESS";
let SEARCH_TIPS = "SEARCH_TIPS";
let SEARCH_TIPS_SUCCESS = "SEARCH_TIPS_SUCCESS";
let CREATE_TIP = "CREATE_TIP";
let CREATE_TIP_SUCCESS = "CREATE_TIP_SUCCESS";
let TIP_BY_ID = "TIP_BY_ID";
let TIP_BY_ID_SUCCESS = "TIP_BY_ID_SUCCESS";
let FINISH_TIP = "FINISH_TIP";
let FINISH_TIP_SUCCESS = "FINISH_TIP_SUCCESS";
let DELETE_TIP = "DELETE_TIP";
let DELETE_TIP_SUCCESS = "DELETE_TIP_SUCCESS";


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LIST_TIPS:
            return { ...state, fetching_list: 1, }
        case LIST_TIPS_SUCCESS:
            return { ...state, fetching_list: 2, data: action.payload }
        case SEARCH_TIPS:
            return { ...state, fetching_search: 1, data: [] }
        case SEARCH_TIPS_SUCCESS:
            return { ...state, fetching_search: 2, data: action.payload }
        case CREATE_TIP:
            return { ...state, fetching_create: 1 }
        case CREATE_TIP_SUCCESS:
            return { ...state, fetching_create: 2, data: action.payload }
        case TIP_BY_ID:
            return { ...state, fetching_by_id: 1 }
        case TIP_BY_ID_SUCCESS:
            return { ...state, fetching_by_id: 2, row: action.payload }
        case FINISH_TIP:
            return { ...state, fetching_finish: 1 }
        case FINISH_TIP_SUCCESS:
            return { ...state, fetching_finish: 2, pdfs: action.payload }
        case DELETE_TIP:
            return { ...state, fetching_delete: 1 }
        case DELETE_TIP_SUCCESS:
            return { ...state, fetching_delete: 2 }
        case CURRENT_TIPS:
            return { ...state, fetching_current: 1 }
        case CURRENT_TIPS_SUCCESS:
            return { ...state, fetching_current: 2, row: action.payload }
        default:
            return state;
    }
}

export let getTips = () => (dispatch, getState) => {
    dispatch({
        type: LIST_TIPS,
    });

    let branch = JSON.parse(localStorage.getItem("branch"))
    let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))


    let url = `${API_URL}/api/tip?branch_id=${branch.id}&date_init=${date_init}&date_end=${date_end}`;

    fetch(url, {
        headers: getHeaders(),
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: LIST_TIPS_SUCCESS,
            payload: resp.results
        });
    })
}

export let getTipByDate = () => (dispatch, getState) => {
    dispatch({
        type: CURRENT_TIPS,
    });

    let branch = JSON.parse(localStorage.getItem("branch"))
    let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))


    let url = `${API_URL}/api/tip-by-date?branch_id=${branch.id}&date_init=${date_init}&date_end=${date_end}`;

    fetch(url, {
        headers: getHeaders(),
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: CURRENT_TIPS_SUCCESS,
            payload: resp
        });
    })
}

export let setTemporalData = (list) => (dispatch, getState) => {
    let _list = list.filter(t => t.tips === true)
    localStorage.setItem("tips", JSON.stringify(_list))
}

export let onSaveTip = (tip, allowed, listWorker) => (dispatch, getState) => {
    dispatch({
        type: CREATE_TIP,
    });

    let form = {}

    let branch = JSON.parse(localStorage.getItem("branch"))

    let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))
    form.branch_id = branch.id
    form.date_init = date_init
    form.date_end = date_end
    form.tips = tip
    form.allowed = allowed

    form.list_worker = listWorker


    let url = `${API_URL}/api/tip`;


    fetch(url, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(form)
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: CREATE_TIP_SUCCESS,
            payload: resp
        });
    })
}

export let onSearchData = () => (dispatch, getState) => {
    dispatch({
        type: SEARCH_TIPS,
    });

    let branch = JSON.parse(localStorage.getItem("branch"))
    let { date_init, date_end } = JSON.parse(localStorage.getItem("date"))

    let url = `${API_URL}/api/tip-search?branch_id=${branch.id}&date_init=${date_init}&date_end=${date_end}`;
    //let url = `${API_URL}/api/tip-search?branch_id=9&date_init=2022-05-01&date_end=2022-05-31`;

    fetch(url, {
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: SEARCH_TIPS_SUCCESS,
            payload: resp
        });
    })
}
export let getTipId = (id) => (dispatch, getState) => {
    dispatch({
        type: TIP_BY_ID,
    });

    let url = `${API_URL}/api/tip/${id}`;

    fetch(url, {
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        console.log('resp', resp);

        dispatch({
            type: TIP_BY_ID_SUCCESS,
            payload: resp.results
        });
    })
}

export let onFinishTip = (id) => (dispatch, getState) => {
    dispatch({
        type: FINISH_TIP,
    });

    let url = `${API_URL}/api/tip-finish/${id}`;

    fetch(url, {
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: FINISH_TIP_SUCCESS,
            payload: resp
        });
    })
}

export let onDeleteTip = (id) => (dispatch, getState) => {
    dispatch({
        type: DELETE_TIP,
    });

    let url = `${API_URL}/api/tip/${id}`;

    fetch(url, {
        method: "DELETE",
        headers: getHeaders()
    }).then(resp => resp.json()).then(resp => {
        dispatch({
            type: DELETE_TIP_SUCCESS,
            payload: resp
        });
    })
}