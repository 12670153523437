import React, { useEffect, useState } from 'react'
import { formatNumber } from '../../utils'

const ItemTip = ({ item, updateRow, updateRowMonth }) => {


    const updateField = (e) => {
        let value = e.target.value
        item.assign_point = value
        updateRow(item)
    }

    const updateFieldDay = (e) => {
        let value = e.target.value
        item.worker_days = value
        updateRowMonth(item)
    }

    return (
        <tr>
            <td>{item.name}</td>
            <td>{item.document}</td>
            <td>{item.position.description}</td>
            <td><input className='form-control' value={item.assign_point} onChange={updateField} /></td>
            <td>{item.assign_point > 0 ? (item.points_worker_days) : 0}</td>
            <td><input className='form-control' value={item.worker_days} onChange={updateFieldDay} /></td>
            <td align='right'>{item.assign_point > 0 ? formatNumber(item.percent) : 0}</td>
            <td align='right'>{formatNumber(item.total)}</td>
        </tr>
    )
}

export default ItemTip;